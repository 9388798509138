import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Resume from "./views/Resume.vue";
import Blog from "./views/Blog.vue";
import Contact from "./views/Contact.vue";
import Portfolio from "./views/Portfolio.vue";
import Settings from "./views/Settings.vue";
import NotFoundComponent from "./views/404.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "home",
      name: "root",
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
    },
    {
      path: "/about/resume",
      name: "resume",
      component: Resume,
    },
    {
      path: "/resume",
      redirect: {
        name: "resume",
      },
    },
    {
      path: "/home",
      name: "home",
      component: Home,
    },
    {
      path: "/blog",
      name: "blog",
      component: Blog,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
    {
      path: "/portfolio",
      name: "portfolio",
      component: Portfolio,
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
    },
    {
      path: "*",
      component: NotFoundComponent,
    },
  ],
});
