<template>
    <div>
        <a class=pdf href="/resume.png"><i class="iconfont icon-pdf"></i> 简历长图</a>
        <p class=last-modified>最后更新时间：2018年2月</p>
        <div class=content>
            <div class=content-bd>
                <div class=content-left>

                    <section class=practice>
                        <header class=section-hd>
                            <span class=section-title-l></span>
                            <h2 class=section-title>实践经历</h2>
                            <span class=section-title-r></span></header>
                        <div class=section-bd>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2018.05 ~ 至今</span>
                                    <a class="btn item-more" href=http://weizhan.gmw.cn/web/index.php? target=_blank>工作</a>
                                    <h3 class=item-name>中化能源科技有限公司</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>项目单元负责人</strong><strong>前端工程师</strong>
                                        <ul>
                                          <li>负责2C-mobile项目前端单元开发。</li>
                                          <li>负责项目架构，配置部署环境，前端性能优化；</li>
                                          <li>积极沟通以呈现最好的用户体验及视觉效果。</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2015.12 ~ 2018.04</span>
                                    <a class="btn item-more" href=http://weizhan.gmw.cn/web/index.php? target=_blank>工作</a>
                                    <h3 class=item-name>光明云科技有限公司</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>技术部-经理</strong><strong>前端工程师</strong>
                                        <ul>
                                          <ul>负责需求分析，进度评估以及跟踪，评估项目风险，对产品及运营需求进行方案设计、实现及持续优化。</ul>
                                          <ul>对业务目标及技术目标的达成负责，对问题快速响应，利用工程化的手段提高研发效率、质量。</ul>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <!-- <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2015.09 ~ 2016.04</span>
                                    <a class="btn item-more" href=http://www.uncentury.com target=_blank>工作</a>
                                    <h3 class=item-name>北京联盛世纪科技有限公司</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>设计部-前端工程师</strong>
                                        负责内部孵化项目的前端开发及<a style="color: #333;" target=_blank href="http://www.uncentury.com/"><em>官方网站</em></a>的开发维护。
                                    </p>
                                </div>
                            </div> -->

                            <!-- <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2015.04 ~ 2015.08</span>
                                    <a class="btn item-more" href=http:// target=_blank>实习</a>
                                    <h3 class=item-name>北京太一互联科技有限公司</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content><strong>前端工程师</strong>
                                        负责O2O平台视图层和控制层代码的编写，同期也在学习后端技术以提高开发效率。
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </section>

                    <section class=project>
                        <header class=section-hd>
                            <span class=section-title-l></span>
                            <h2 class=section-title>项目经验</h2>
                            <span class=section-title-r></span>
                        </header>
                        <div class=section-bd>
                              <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2018.10 ~ 至今</span>
                                    <a class="btn item-more" href="zhaosky.cn" target="_blank">zhaosky.cn</a>
                                    <h3 class=item-name>赵大仁 · SKY ZHAO</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>mobile+PC</strong>
                                        
                                          个人主页，会不定期更新，欢迎大家提出宝贵的意见来帮助丰富小站内容，优化用户体验。
                                          <br><span class="green" @click="$router.push({name:'contact'})">我要提意见！</span>
                                        
                                        <br>#<em>vue</em>,<em>vue-router</em>,<em>vuex</em>,<em>swiper</em>,<em>axios</em>,<em>scss</em>,<em>webpack</em>,<em>jenkins</em>,<em>docker</em>#
                                    </p>
                                </div>
                            </div>

                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2018.05 ~ 至今</span>
                                    <a class="btn item-more" href="https://m.sinochem-fjxs.com/weixin/filter/common/redirectFlag?flag=memberCenter&sbxid=wx175f54b5df1158b4" target="_blank">小化加油</a>
                                    <h3 class=item-name>小化加油 - 公众号</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>前端负责人</strong><strong>mobile</strong><strong>toC</strong>
                                        
                                          对项目进行入口拆分以及依赖文件的提取，解决了首屏加载慢的遗留问题。
                                          针对地图、弹窗、选择器、滑动容器等功能制作公共组件。
                                          优化开发、发版流程，管理git分支，部署自动化发版工具。
                                        
                                        <br>#<em>vue</em>,<em>vue-router</em>,<em>vuex</em>,<em>better-scroll</em>,<em>axios</em>,<em>scss</em>,<em>webpack</em>,<em>jenkins</em>,<em>docker</em>#
                                    </p>
                                </div>
                            </div>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2018.05 ~ 至今</span>
                                    <!-- <a class="btn item-more" href="https://m.sinochem-fjxs.com/weixin/filter/common/redirectFlag?flag=memberCenter&sbxid=wx175f54b5df1158b4" target="_blank">小化加油</a> -->
                                    <h3 class=item-name>管理平台/大数据展示平台</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>前端工程师</strong><strong>PC</strong><strong>toB</strong>

                                          图表、表单类开发，包含大量复杂表单交互的处理。
                                        
                                        <br>#<em>vue</em>,<em>vue-router</em>,<em>axios</em>,<em>element-ui</em>,<em>echarts</em>,<em>scss</em>,<em>webpack</em>,<em>jenkins</em>#
                                    </p>
                                </div>
                            </div>
                            <!-- <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2018.01 ~ 2018.04</span>
                                    <a class="btn item-more" href=http://ws.gmym.cn/app/index.php?i=76 target=_blank>Link</a>
                                    <h3 class=item-name>光明推广平台</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>前端负责人</strong>为推动公司前端技术栈的发展，首次选择使用<em>vue</em>进行开发。由于项目周期短规模不大，且<em>VUE</em>的学习曲线平缓而快，社区内容丰富适合作为提升技术栈的首选。
                                    </p>
                                </div>
                            </div> -->
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2017.07 ~ 2018.04</span>
                                    <a class="btn item-more" href='http://theory.cloud.gmw.cn/templates/app/index.html' target=_blank>理论号</a>
                                    <h3 class=item-name>光明日报理论号</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>前端负责人</strong><strong>混合式app</strong><strong>网址：theory.cloud.gmw.cn/templates/app/index.html</strong>
                                        政治正确的新闻资讯站点，经历多次改版，解决了大量兼容性问题。
                                        <br>#<em>php</em>,<em>jquery</em>,<em>jsbridge</em>,<em>tmodjs</em>,<em>owl-carousel</em>#
                                    </p>
                                </div>
                            </div>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2016.12</span>
                                    <a class="btn item-more" href='http://ws.gmym.cn/app/index.php?i=76' target=_blank>微信官网</a>
                                    洲际酒店微网站项目
                                    <h3 class=item-name>北京三里屯通盈中心洲际酒店</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>前端负责人</strong><strong>网址：ws.gmym.cn/app/index.php?i=76</strong>

                                        <!-- 由于项目基于公司原有平台开发，后端和控制器使用<em>PHP</em>编写。前端使用<em>H5</em>和<em>jQuery</em>框架快速搭建而成。 -->
                                        <br>#<em>php</em>,<em>jquery</em>,<em>tmodjs</em>,<em>owl-carousel</em>#
                                    </p>
                                </div>
                            </div>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2016.06 ~ 2016.10</span>
                                    <h3 class=item-name>艾索AKESO官网及后台管理系统</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>前端工程师</strong>

                                        <br>#<em>rubyonrails</em>,<em>react</em>,<em>slim</em>,<em>less</em>,<em>echarts</em>#
                                    </p>
                                </div>
                            </div>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2015.09 ~ 2016.04</span>
                                    <h3 class=item-name>智行护驾APP / 智行小弟APP / 智行护驾微信端</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>前端工程师</strong><strong>混合式APP</strong>
                                        在熟练使用ES5、jQuery、Bootstrap的前提下，初识NodeJS环境，学习了运行环境、包等概念。
                                        <br>#<em>jquery</em>,<em>jsbridge</em>,<em>coffeescript</em>,<em>backbone</em>,<em>express</em>,<em>underscore</em>#
                                    </p>
                                </div>
                            </div>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2015.04 ~ 2015.08</span>
                                    <h3 class=item-name>超级中学家教O2O平台</h3>
                                </header>
                                <div class=item-bd>
                                    <p class=section-content>
                                        <strong>前端工程师</strong>
                                        大四阶段与学长的创业项目，负责前后端开发，及路演等内容。
                                        <br>#<em>php</em>,<em>thinkphp</em>,<em>jquery</em>#
                                    </p>
                                </div>
                            </div>
            
                        </div>
                    </section>

                    <section class=skill>
                        <header class=section-hd>
                            <span class=section-title-l></span>
                            <h2 class=section-title>专业技能</h2>
                            <span class=section-title-r></span>
                        </header>
                        <div class=section-bd>
                            <ul class=section-list>
                                <li><p class=section-content><i class="iconfont icon-dot"></i>精通<em>html/css/javascript</em>，熟练掌握<em>Vue</em>、<em>jQuery</em>，掌握<em>reactjs</em>、<em>angular</em>等前端框架。</p></li>
                                <li><p class=section-content><i class="iconfont icon-dot"></i>精通<em>PC端</em>，<em>H5/APP</em>端的前端设计模式，熟悉<em>Webpack</em>、<em>gulp</em>等前端构建工具。</p></li>
                                <li><p class=section-content><i class="iconfont icon-dot"></i>熟悉数据库基本操作，可使用<em>PHP</em>、<em>Nodejs</em>、<em>java</em>、<em>Python</em>等语言进行服务端开发。</p></li>
                                <li><p class=section-content><i class="iconfont icon-dot"></i>熟练使用<em>docker</em>、<em>jenkins</em>等自动化构建工具</p></li>
                                <li><p class=section-content><i class="iconfont icon-dot"></i>重视<em>用户体验</em>，有一定<em>视觉设计</em>能力。</p></li>
                                <!-- <li><p class=section-content><i class="iconfont icon-dot"></i>懂<em>设计</em>，重视<em>用户体验</em>与<em>代码可维护性</em>。</p></li> -->
                                <!-- <li><p class=section-content><i class="iconfont icon-dot"></i>善于使用<em>CSS特性</em><em>打包</em><em>代码优化</em><em>合并请求</em>等方式来进行前端优化。</p></li> -->
                                <!-- <li><p class=section-content><i class="iconfont icon-dot"></i>熟练使用<em>jQuery</em>、<em>Bootstrap等</em>流行框架，有能力自行搭建<em>模块化</em>框架。</p></li> -->
                                <li><p class=section-content><i class="iconfont icon-dot"></i>熟练掌握<em>Git</em>，熟悉多人开发流程。</p></li>
                                <li><p class=section-content><i class="iconfont icon-dot"></i>掌握<em>koa</em>、<em>express</em>等Node后端框架。</p></li>
                                <!-- <li><p class=section-content><i class="iconfont icon-dot"></i>掌握<em>文件打包工具</em>、<em>代码优化工具</em>及<em>扩展语言</em>的使用。</p></li> -->
                                <li><p class=section-content><i class="iconfont icon-dot"></i>可以独立完成<em>微信小程序</em>开发，有过<em>小游戏</em>经验，能够快速掌握新技能，可阅读<em>英文文档</em>，适应英文办公环境。</p></li>
                                <li><p class=section-content><i class="iconfont icon-dot"></i>有责任感，学习能力强，有良好的沟通技巧和团队合作精神。</p></li>
                            </ul>
                        </div>
                    </section>

                    <section class=prize>
                        <header class=section-hd>
                            <span class=section-title-l></span>
                            <h2 class=section-title>奖项证书</h2>
                            <span class=section-title-r></span>
                        </header>
                        <div class=section-bd>
                            <!-- <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2012.06</span>
                                    <span class=item-more>校级</span>
                                    <h3 class=item-name>裕兴杯电子设计竞赛·二等奖</h3>
                                </header>
                            </div>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2013.06</span>
                                    <span class=item-more>校级</span>
                                    <h3 class=item-name>Ti杯电子设计竞赛·一等奖</h3>
                                </header>
                            </div> -->
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2013.09</span>
                                    <span class=item-more>市级</span>
                                    <h3 class=item-name>全国大学生电子设计竞赛（本科组）·一等奖第一名</h3>
                                </header>
                            </div>
                            <div class=item>
                                <header class=item-hd>
                                    <span class=item-time>2013.09</span>
                                    <span class=item-more>全国</span>
                                    <h3 class=item-name>全国大学生电子设计竞赛（本科组）·二等奖</h3>
                                </header>
                            </div>
                        </div>
                    </section>
                </div>
                <header class="content-right content-hd">

                    <section class="title">
                        <!-- <div class="avatar" style="background: url(/static/img/img-1.jpg)"></div> -->
                        <img class=avatar src="../assets/img/avatar.jpg">
                        <div class=name>
                            <h1>赵天</h1>
                        </div>
                    <h2 class="job">前端工程师<br>全栈工程师</h2>
                    </section>

                    <section class=info>
                        <h2>男 / 1992.12.23</h2>
                        <h3>北京工业大学 · 电子信息工程</h3>
                        <h3>北京工业大学 · 广告学双学位</h3>
                        <h3>本科 / 2015年毕业</h3>
                    </section>

                    <!-- <section class="skill-level">
                        <h3>英语</h3>
                        <div class="item i-html">
                            <i class="in"></i><i class="out"></i>
                        </div>
                        <h3>HTML</h3>
                        <div class="item i-html">
                            <i class="in"></i><i class="out"></i>
                        </div>
                        <h3>CSS</h3>
                        <div class="item i-css">
                            <i class="in"></i><i class="out"></i>
                        </div>
                        <h3>JavaScript</h3>
                        <div class="item i-js">
                            <i class="in"></i><i class="out"></i>
                        </div>
                        <h3>PhotoShop</h3>
                        <div class="item i-ps">
                            <i class="in"></i><i class="out"></i>
                        </div>
                    </section> -->

                    <section class=contact>
                        <ul>
                            <li>
                                <a href=http://zhaosky.cn target=_blank>
                                    <i class="iconfont icon-homepage"></i>
                                    <!-- <span class=contact-link>sky-blog.netlify.com</span> -->
                                    <span class=contact-link>zhaosky.cn</span>
                                </a>
                            </li>
                            <li>
                                <a href=https://github.com/boyzhaotian target=_blank>
                                    <i class="iconfont icon-github"></i>
                                    <span class=contact-link>github.com/boyzhaotian</span>
                                </a>
                            </li>
                            <li>
                                <a href=mailto:13426031783@139.com target=_blank>
                                    <i class="iconfont icon-email"></i>
                                    <span class=contact-link>13426031783@139.com</span>

                                </a>
                            </li>
                            <li>
                                <a href=tel:13426031783 target=_blank>
                                    <i class="iconfont icon-phone"></i>
                                    <span class=contact-link>13426031783</span>
                                </a>
                            </li>
                        </ul>
                    </section>
                </header>

            </div>
            <footer class=print-footer>
                <a class=footer-link href=https://boyzhaotian.github.io/resume-1 target=_blank><i class="iconfont icon-link"></i> 网页版简历：boyzhaotian.github.io/resume</a>
            </footer>
        </div>
        <footer class=github-footer><a class=footer-link href=https://github.com/boyzhaotian/resume-1 target=_blank><i class="iconfont icon-link"></i> github.com/boyzhaotian/resume</a>
        </footer>
    </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", sans-serif;
  color: inherit;
}

html {
  line-height: 1;
}

body {
  line-height: 21px;
  font-weight: 400;
  font-size: 14px;
  color: #333;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

a img {
  border: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

@font-face {
  font-family: "iconfont";
  src: url("~@/fonts/iconfont.ttf") format("truetype");
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
}

.iconfont {
  color: #00b38a;
  font-family: "iconfont" !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e622";
}

.icon-github:before {
  content: "\e69f";
}

.icon-link:before {
  content: "\e609";
}

.icon-homepage:before {
  content: "\e615";
}

.icon-email:before {
  content: "\e63b";
}

.icon-dot:before {
  content: "\e600";
}

.kill-ie {
  margin: 0;
  padding: 0.5em 0;
  color: #333;
  text-align: center;
  background: #fff8e2;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  background-color: #eee;
  overflow: auto;
  padding-top: 5px;
}

@media screen and (max-width: 1024px) {
  body {
    padding-top: 0;
  }
}

a {
  text-decoration: none;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.last-modified {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 1000px;
  color: #bbb;
  margin-left: -500px;
  font-size: 12px;
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .last-modified {
    display: none;
  }
}

.content {
  position: relative;
  width: 1024px;
  margin: 50px auto;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 0 15px #c0c0c0;
  -webkit-box-shadow: 0 0 15px #c0c0c0;
  box-shadow: 0 0 15px #c0c0c0;
  background-color: #fff;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .content {
    width: 100%;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
}

.content-hd {
  width: 100%;
  height: inherit;
  color: #fff;
  background-color: #eee;
  overflow: hidden;
  .title {
    width: 100%;
    font-size: 52px;
    font-weight: 300;
    overflow: hidden;
    padding-bottom: 5px;
    padding-top: 40px;
  }
  .avatar {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid;
  }
  .name {
    text-align: center;
    color: #00b38a;
    h1 {
      margin-top: 10px;
      font-family: "Lucida Grande", "Hiragino Sans GB", "Hiragino Sans GB W3",
        "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", sans-serif;
      font-weight: 500;
      letter-spacing: 5px;
    }
    small {
      font-weight: 400;
      letter-spacing: 0;
      font-size: 24px;
      padding-left: 10px;
    }
  }
  .job {
    text-align: center;
    color: #00b38a;
    font-size: 24px;
  }
  .skill-level {
    padding-top: 80px;
    h3 {
      color: #999;
      margin-left: 5px;
    }
    .item {
      display: flex;
      width: 260px;
      height: 12px;
      border-radius: 6px;
      margin-bottom: 15px;
      background: #d7d7d7;
      .in {
        background: #00b38a;
        height: 12px;
        width: 12px;
      }
      .out {
        background: #d7d7d7;
        height: 12px;
        width: 12px;
      }
      .in:first-child {
        border-radius: 6px 0 0 6px;
      }
      .out:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
    .i-html {
      .in {
        flex: 8;
      }
      .out {
        flex: 2;
      }
    }
    .i-css {
      .in {
        flex: 7;
      }
      .out {
        flex: 3;
      }
    }
    .i-js {
      .in {
        flex: 7;
      }
      .out {
        flex: 3.5;
      }
    }
    .i-sass {
      .in {
        flex: 6;
      }
      .out {
        flex: 4;
      }
    }
    .i-ps {
      .in {
        flex: 7.5;
      }
      .out {
        flex: 2.5;
      }
    }
  }
  .info {
    color: #555;
    line-height: 30px;
    h2 {
      margin: 30px 0 4px;
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .contact {
    padding-top: 100px;
    font-weight: 400;
    ul {
      margin: 8px 0 4px;
      > li {
        height: 22px;
        margin-top: 10px;
        > a {
          color: #555;
          font-family: "Lucida Grande", "Hiragino Sans GB",
            "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial,
            "Microsoft YaHei", sans-serif;
          font-size: 16px;
          line-height: 22px;
          &:hover {
            color: #999;
          }
          .iconfont {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .content-hd {
    padding: 25px 30px;
  }
}

@media screen and (max-width: 720px) {
  .content-hd .title {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .content-hd {
    .name,
    .job,
    .info,
    .contact {
      width: 100%;
      text-align: center;
    }
  }
}

@media screen and (max-width: 720px) {
  .content-hd .avatar {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 720px) {
  .content-hd .name h1 {
    letter-spacing: 0;
    line-height: 1.25;
  }
}

@media screen and (max-width: 720px) {
  .content-hd .name small {
    display: block;
    padding-left: 0;
    font-size: 20px;
    line-height: 1;
  }
}

@media screen and (max-width: 720px) {
  .content-hd .job {
    margin: 0 auto 0;
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .content-hd .skill-level {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  .content-hd .info h2 {
    margin-top: 4px;
  }
}

@media screen and (max-width: 1024px) {
  .content-hd .contact {
    padding-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .content-hd .contact ul {
    text-align: center;
    margin: 16px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .content-hd .contact ul > li {
    display: inline-block;
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .content-hd .contact ul > li > a .contact-link {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .content-hd .contact ul > li > a .iconfont {
    font-size: 20px;
  }
}

.content-bd {
  display: flex;
  min-height: 200px;
  flex-flow: row wrap;
  overflow: hidden;
  font-size: 14px;
  .content-left {
    flex: 6.5;
    padding: 20px 30px 50px;
  }
  .content-right {
    box-sizing: border-box;
    padding: 20px 30px 50px;
    flex: 3.5;
    background: #eeeeee;
  }
  section {
    overflow: hidden;
    padding: 10px 15px;
  }
  .section-hd {
    position: relative;
    height: 32px;
    margin: 10px auto 5px;
    overflow: hidden;
  }
  .section-bd {
    overflow: hidden;
  }
  .section-title-l,
  .section-title-r {
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 35%;
    height: 0;
    vertical-align: middle;
    border-top: 1px solid #ededed;
  }
  .section-title-l {
    left: 0;
  }
  .section-title-r {
    right: 0;
  }
  .section-title {
    position: relative;
    z-index: 1;
    width: 24%;
    min-width: 100px;
    margin: 0 auto;
    padding: 4px 10px;
    -moz-border-radius: 32px;
    -webkit-border-radius: 32px;
    border-radius: 32px;
    font-size: 16px;
    text-align: center;
    background-color: #eee;
  }
  .section-list {
    padding: 10px 0 5px;
  }
  .item-hd {
    position: relative;
    padding: 10px 0 5px;
    line-height: 20px;
    overflow: hidden;
    font-weight: 500;
    font-size: 14px;
    .iconfont {
      color: #00b38a;
    }
    .item-time {
      display: inline-block;
      float: left;
      width: 25%;
    }
    .item-more {
      float: right;
      margin-left: 5px;
    }
    .item-name {
      display: inline-block;
      float: left;
    }
  }
  .item-bd {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .section-content {
    text-align: justify;
    word-break: break-all;
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;
    .iconfont {
      color: #00b38a;
    }
    em {
      display: inline-block;
      font-family: Menlo, "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei",
        sans-serif;
      font-size: 12px;
      line-height: 14px;
      border: 1px solid #d1d1d1;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      margin: 2px;
      padding: 0 3px;
      background-color: #f7f7f7;
    }
    strong {
      font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei",
        sans-serif;
      font-size: 12px;
      line-height: 14px;
      border: 1px solid #f3f3f3;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      font-weight: 500;
      color: #444;
      margin: 2px;
      padding: 0 3px;
      background-color: #f6f6f6;
    }
  }
  .skill {
    .section-content {
      margin-bottom: 5px;
      line-height: 24px;
    }
    strong {
      display: inline;
      line-height: 18px;
    }
  }
  .practice .item-time {
    width: 32%;
  }
  .prize {
    font-weight: 500;
    .item-hd {
      padding: 12px 0 0;
    }
    .item-time {
      width: 15%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .content-bd .content-left {
    flex: 1 100%;
    order: 2;
    padding: 20px 10px 50px;
  }
}

@media screen and (max-width: 1024px) {
  .content-bd .content-right {
    flex: 1 100%;
    order: 1;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 720px) {
  .content-bd .item-hd .item-time {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 720px) {
  .content-bd .item-hd .item-more {
    position: absolute;
    top: 10px;
    right: 0;
    margin-top: 1px;
  }
}

@media screen and (max-width: 720px) {
  .content-bd .practice .item-time {
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .content-bd .prize .item-time {
    width: 100%;
  }
}

.btn {
  display: inline-block;
  padding: 0 5px;
  border: 1px solid #00b38a;
  border-radius: 3px;
  color: #00b38a;
  font-family: Menlo, "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei",
    sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  &:hover {
    color: #fff;
    background-color: #00b38a;
  }
}

.pdf {
  display: inline-block;
  position: fixed;
  z-index: 10;
  bottom: 6px;
  left: 50%;
  margin-left: 520px;
  padding: 0 8px;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  background-color: #00b38a;
}

@media screen and (max-width: 1246px) {
  .pdf {
    left: auto;
    right: 8px;
    margin-left: 0;
  }
}

footer {
  text-align: center;
  .footer-link {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 25px;
    .icon-link {
      color: #d1d1d1;
      font-size: 16px;
    }
  }
  &.print-footer {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  footer .footer-link {
    margin-top: 25px;
  }
}

::-webkit-scrollbar {
  background-color: #f1f1f1;
  overflow: visible;
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  min-height: 15px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  &:vertical {
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::selection,
::-moz-selection {
  background: #00b38a;
  color: #fff;
}

@media print {
  @page {
    margin: 0;
  }

  .content {
    width: 970px;
    height: 1365px;
    margin: 0 auto;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 0;
    .content-hd {
      height: 1365px;
    }
    .print-hidden {
      display: none;
    }
  }
  .pdf {
    display: none;
  }
  footer {
    &.github-footer {
      display: none;
    }
    &.print-footer {
      display: block;
      .footer-link {
        position: absolute;
        left: 45px;
        bottom: 25px;
        color: #c0c0c0;
        display: inline-block;
      }
    }
  }
}
</style>
